import ajax from "@/ajax";

export default {
  loadUsersByUsernameAndEmail(email, username) {
    return ajax
      .get("/api/moodle/searchbasic", {
        params: {
          username: username,
          email: email
        }
      })
      .then(res => {
        return res.data;
      });
  },
  saveUsers(groupId, groupName, users) {
    return ajax
      .post("/api/moodle/users/save", {
        users: users,
        groupId: groupId,
        groupName: groupName
      })
      .then(res => {
        return res.data;
      });
  },
  loadGroups() {
    return ajax.get("/api/groups").then(res => {
      return res.data;
    });
  },
  loadGroupMembers(groupId) {
    return ajax
      .get("/api/groups/members", {
        params: { groupId: groupId }
      })
      .then(res => {
        return res.data;
      });
  },
  findUsers(query) {
    return ajax
      .get("/api/moodle/search", {
        params: { query: query }
      })
      .then(res => {
        return res.data;
      });
  },
  enrollUser(id, days) {
    return ajax
      .post(
        "/api/moodle/users/enroll",
        {},
        {
          params: { id: id, days: days }
        }
      )
      .then(res => {
        return res.data;
      });
  },
  deleteUser(id) {
    return ajax
      .delete("/api/moodle/users/enroll", {
        params: { id: id }
      })
      .then(res => {
        return res.data;
      });
  },
  getCourses() {
    return ajax.get("/api/courses").then(res => {
      return res.data;
    });
  },
  getQuestions(courseId, questionNumbers, setNumbers) {
    return ajax
      .get("/api/questions", {
        params: {
          courseId: courseId,
          numberOfQuestion: questionNumbers,
          numberOfSet: setNumbers
        }
      })
      .then(res => {
        return res.data;
      });
  }
};
