<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container>
      <v-row class="left">
        <v-col class="mb-4">
          <p class="text-h5">
            Generowanie zestawów egzaminacyjnych
          </p>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="formValid">
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="questionNumbers"
              :rules="fieldRulesQuestions"
              label="Ilość pytań w zestawie"
              required
            ></v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field
              v-model="setNumbers"
              :rules="fieldSetQuestions"
              label="Ilość zestawów"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-select
              :items="courses"
              item-text="name"
              item-value="id"
              v-model="selectedCourse"
              :rules="fieldCourse"
              label="Wybierz szkolenie"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-btn
              color="primary"
              elevation="1"
              class="mr-4"
              :loading="loading"
              @click="getQuestions"
            >
              Generuj pytania
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!questions"
              elevation="1"
              class="mr-4"
              @click="printQuestions"
            >
              Drukuj pytania
              <v-icon right dark>mdi-printer</v-icon>
            </v-btn>
            <!-- SOURCE -->
            <div v-show="false" id="printMe">
              <div v-for="(set, index) in questions" :key="set">
                <div style="page-break-after: always; padding-bottom: 30px;">
                  <p
                    style="text-align: center; font-weight: bold; font-size: large"
                  >
                    Zestaw egzaminacyjny nr {{ index + 1 }} <br /><br />
                    "{{
                      courses.filter(function(o) {
                        return o.id == selectedCourse;
                      })[0].name
                    }}"
                  </p>
                  <ol style="padding-bottom: 30px;">
                    <li
                      v-for="question in set"
                      :key="question"
                      style="padding-bottom: 10px;"
                    >
                      {{ question.text }}
                      <ol type="a">
                        <li
                          v-for="answer in question.answers"
                          :key="answer"
                          style="padding-bottom: 5px; padding-top:5px; "
                        >
                          {{ answer.text }}
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
                <div>
                  <p
                    style="text-align: center; font-weight: bold; font-size: large"
                  >
                    Odpowiedzi na pytania i podstawa prawna, zestaw nr
                    {{ index + 1 }} <br /><br />
                    "{{
                      courses.filter(function(o) {
                        return o.id == selectedCourse;
                      })[0].name
                    }}"
                  </p>
                  <ol>
                    <li v-for="question in set" :key="question">
                      <span
                        v-for="(answer, index2) in question.answers"
                        :key="answer"
                      >
                        <span v-if="answer.corect && index2 === 0">a</span>
                        <span v-if="answer.corect && index2 === 1">b</span>
                        <span v-if="answer.corect && index2 === 2">c</span>
                      </span>
                    </li>
                  </ol>
                </div>
                <div style="page-break-after: always;">
                  <ol>
                    <li v-for="question in set" :key="question">
                      {{ question.feedback }}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import MoodleService from "@/api/MoodleService";

export default {
  name: "Documents",
  data: () => ({
    formValid: false,
    questionNumbers: 5,
    setNumbers: 1,
    selectedCourse: null,
    courses: [],
    questions: [],
    fieldCourse: [v => console.log(v) || !!v || "Pole wymagane"],
    fieldRulesQuestions: [
      v => !!v || "Pole wymagane",
      v =>
        (!isNaN(parseFloat(v)) && v > 0 && v <= 30) ||
        "Maksymalna ilość pytań w zestawie do 30"
    ],
    fieldSetQuestions: [
      v => !!v || "Pole wymagane",
      v =>
        (!isNaN(parseFloat(v)) && v > 0 && v <= 5) ||
        "Maksymalna ilość generownych zestawów do 5"
    ]
  }),
  methods: {
    getQuestions(event) {
      this.$refs.form.validate();
      console.log(event);
      if (this.formValid) {
        this.loading = true;
        this.questions = null;
        MoodleService.getQuestions(
          this.selectedCourse,
          this.questionNumbers,
          this.setNumbers
        )
          .then(res => {
            console.log(res);
            this.loading = false;
            this.questions = res;
          })
          .catch(error => {
            console.log(error);
            this.showError = true;
          });
      }
    },
    printQuestions() {
      this.$htmlToPaper("printMe");
    }
  },
  created() {
    MoodleService.getCourses()
      .then(res => {
        console.log(Object.keys(res));
        this.loading = false;
        this.courses = res;
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
        this.showError = true;
      });
  }
};
</script>
